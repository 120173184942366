import React, {useEffect, useState} from 'react';
import CollageRow from "./CollageRow";
import CollageItem from "./CollageItem";






function Collage2023(props) {
    const [display, setDisplay] = useState([])

    useEffect(() => {
        const map = []
        for(let i = 0; i < 8; i++) {
           map.push(
               <div key={i} className="w-screen h-[28vh] mb-[0.125rem]">
                   <CollageRow id={i} key={i} img1={<CollageItem img={i*4+1}/>} img2={<CollageItem img={2+i*4}/>} img3={<CollageItem img={3+i*4}/>} img4={<CollageItem img={4+i*4}/>}/>
               </div>
           )
        }
        setDisplay(map)
    }, [])


    return (
        <div className="w-fullF h-[250vh] bg-black flex flex-col items-center justify-center overflow-x-hidden">
            <div className="w-[80vw] text-white xl:text-[2.5rem] mobile:text-[1rem] h-[25vh] text-center mt-8"> Ich hab die .-Gruppe (und Julia lol) gefragt, mir ihre Lieblingsbilder mit dir zu senden. <br/>
                  Das kam dabei raus:
            </div>
            <div className="w-fullF h-fit">
                {display}
            </div>

        </div>
    );
}

export default Collage2023;
