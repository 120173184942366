import React from 'react';

function CollageRow({ id, img1, img2, img3, img4 }) {
    return (
        <div className="flex flex-row w-full h-full justify-evenly items-center" key={id}>
            {img1}
            {img2}
            {img3}
            {img4}
        </div>
    );
}

export default CollageRow;