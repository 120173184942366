import React, {useEffect, useState} from 'react';

function CollageItem({img, desc}) {
    const [selected, setSelected] = useState(false);
    const [show, setShow] = useState(false);
    let text;
    const imagePath = "/imgs/img"+img+".jpg";

    switch (img){
        case 1:
            text = " Aktives Planen und so - Luca";
            break;
        case 2:
            text= " Das ist Frau Lenkes Sohn? - Cora";
            break;
        case 3:
            text= " Glaube mein Hund mag dich - Luca";
            break;
        case 4:
            text= " Glaube mein Hund mag dich2 - Luca";
            break;
        case 5:
            text= " Hayday oder so - Cora";
            break;
        case 6:
            text= " Handball let's go! - Cora";
            break;
        case 7:
            text= " Wir mögen uns sagten sie... - Cora";
            break;
        case 8:
            text= " Schere - Luca";
            break;
        case 9:
            text= " 900 Euro haben sich gelohnt - Luca";
            break;
        case 10:
            text= " Sport ist super - Cora ";
            break;
        case 11:
            text= " Warum eigentlich Herr Heimpfling? - Cora";
            break;
        case 12:
            text= " Keine Ahnung um ehrlich zu sein - Lauren";
            break;
        case 13:
            text= " Aufschlag - Julia";
            break;
        case 14:
            text= " Team oder so idk bin kein Experte - Julia ";
            break;
        case 15:
            text= " EZ - Julia";
            break;
        case 16:
            text= " Aufm' Hauchen - Julia ";
            break;
        case 17:
            text= " Skifahren - Julia ";
            break;
        case 18:
            text= " Fußballweihnachtsfeier - Julia  ";
            break;
        case 19:
            text= " Bowling mit unserer Volleyball Mannschaft - Julia ";
            break;
        case 20:
            text= " (Beste Qualität EO-West) - Julia";
            break;
        case 21:
            text= " Sternsingen (ohne Alkohol und so) - Julia";
            break;
        case 22:
            text= " Wer hat'n dir das Moaß verkauft? - Lauren";
            break;
        case 23:
            text= " Da ist Angi noch nicht kleiner - Lauren";
            break;
        case 24:
            text= " Was geht jetzt ab? - Cora";
            break;
        case 25:
            text= " Weiß ich auch nicht - Cora";
            break;
        case 26:
            text= " (Du hast den nicht rein gemacht) - Cora";
            break;
        case 27:
            text= " Prob. das beste Volleyballbild von dir - Luca";
            break;
        case 28:
            text= " Minigolf in Kempten - Luca";
            break;
        case 29:
            text= " Ja keine Ahnung, war wild - Luca";
            break;
        case 30:
            text= " Gruppenfoto :)) - Luca";
            break;
        case 31:
            text= " Null abgeschnitten - Luca";
            break;
        case 32:
            text= " Nochmal Gruppenfoto :) - Luca";
            break;
        default:
            text = "Test "+ desc;
            break;
    }

    const handleClick = () => {
        setShow(true);
        // Scroll the window to the top
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className={"h-full w-[22vw] bg-cover bg-center bg-[image:var(--image-url)]"} style={{'--image-url': `url(${imagePath})`}} onMouseEnter={() => setSelected(true)} onMouseLeave={()=> setSelected(false)}
             onClick={handleClick}>
            {selected?
                <div className="w-full h-full bg-black-t-50 flex items-center justify-center"> <div className={"w-[80vw] h-full text-white text-[0.8rem] flex items-center justify-center"}>{text}</div> </div>
                :
                <div onClick={() => setShow(false)}></div>
            }
            {show?
                <div className="absolute top-0 left-0 w-fullF h-full">
                    <div className="w-full h-full bg-black-t-50 z-[1] flex items-center justify-center"  onClick={(e) => {
                        // Stop event propagation to prevent toggling `show` again
                        e.stopPropagation();
                        setShow(false);
                    }}>
                        <div
                            className="w-[80vw] h-[80vh] bg-white rounded-2xl flex items-center justify-center z-[2]"
                            onClick={() => setShow(false)}>
                            <img src={imagePath}  onClick={(e) => {
                                // Stop event propagation to prevent toggling `show` again
                                e.stopPropagation();
                                setShow(false);
                            }} className="max-h-full" />
                        </div>
                    </div>

                </div>
                :
                <div></div>
            }
        </div>
    );
}

export default CollageItem;