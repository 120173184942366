import React, {useEffect, useState} from 'react';
import FireWorks from "./Fireworks";
import {useNavigate} from "react-router-dom";

function Front(props) {


    const [timer, setTimer] = useState("");
    const [subTxt, setText] = useState("");
    const [btn, setBtn] = useState(<div></div>);
    const [fr, setFR] = useState(<div></div>);
    const deadline = "Apr 22, 2025 00:00:00";
    const olddeadline = "Apr 22, 2024 00:00:00";
    let interval;
    const navigate = useNavigate();

    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        if(time > 0){
            const days = Math.floor((time%(1000*60*60*24*365))/(1000*60*60*24));
            const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((time % (1000 * 60)) / 1000);
            let seconds_s;
            let days_s;
            let hours_s
            let minutes_s;
            if(seconds <10){
                seconds_s = "0"+seconds+"s ";
            }else{
                seconds_s = seconds+"s ";
            }
            if(days < 10){
                days_s = "0"+days+"d ";
            }else{
                days_s = days+"d ";
            }
            if(hours <10){
                hours_s = "0"+hours+"h ";
            }else{
                hours_s = hours+"h ";
            }
            if(minutes < 10){
                minutes_s = "0"+minutes+"m ";
            }else{
                minutes_s = minutes+"m ";
            }
            const s = days_s+hours_s+minutes_s+seconds_s;
            setText("bis zu deinem Geburtstag.");
            if( Date.parse(olddeadline) - Date.now() < 0){
                setBtn(<div className="z-10 clickText text-[3.5rem] font-bold" onClick={ () => navigate("/2023")}>Klick mich!</div>);
            }else{
                setBtn(<div className="z-10"></div>);
                setFR(<div></div>)
            }
            setTimer(s);
        }else{
            clearInterval(interval);
            setText("");
            setTimer("Alles gute zum Geburtstag!");
            setFR(<div className="noPrio">
                <FireWorks className="noPrio"/>
            </div>)
        }
    };

    useEffect(() => {
        interval = setInterval(() => getTime(deadline), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="w-screen h-screen bg-black select-none">
            <div className="w-full h-1/2 text-white text-center text-9xl z-10">
                <div className="w-full absolute top-1/3 text-center xl:text-9xl md:text-8xl mobile:text-3xl z-10">
                    {timer}
                    <div className="xl:text-3xl mobile:text-xl mx-auto z-10">
                        <p>{subTxt}</p>
                    </div>
                    {btn}
                    {fr}
                </div>
            </div>
        </div>

    );
}

export default Front;
